import React from "react";

const IconCalendarWithNumber = props => (
	<svg viewBox="0 0 41.621 42" {...props}>
		<defs>
			<style>
				{
					".calendar-1,.calendar-2,.calendar-3{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:2px}.calendar-1{stroke-linejoin:round}.calendar-2{stroke-linecap:round}"
				}
			</style>
		</defs>
		<g id="Groupe_411" data-name="Groupe 411" transform="translate(-157 -442)">
			<path
				id="Ligne_16"
				d="M0 0h39.317"
				className="calendar-1"
				data-name="Ligne 16"
				transform="translate(158.152 457.953)"
			/>
			<path
				id="Ligne_17"
				d="M0 0v8.88"
				className="calendar-2"
				data-name="Ligne 17"
				transform="translate(166.729 443)"
			/>
			<path
				id="Ligne_18"
				d="M0 0v8.88"
				className="calendar-2"
				data-name="Ligne 18"
				transform="translate(186.691 443)"
			/>
			<path
				id="Ligne_19"
				d="M0 0v8.88"
				className="calendar-2"
				data-name="Ligne 19"
				transform="translate(176.672 443)"
			/>
			<path
				id="Trac\xE9_23"
				d="M13.5 30h7.59v5.541h-6.3v5.617h7.59"
				className="calendar-3"
				data-name="Trac\xE9 23"
				transform="translate(153.836 434.86)"
			/>
			<path
				id="Trac\xE9_24"
				d="M29.2 30h7.666v11.158H29.2"
				className="calendar-3"
				data-name="Trac\xE9 24"
				transform="translate(150.053 434.86)"
			/>
			<path
				id="Ligne_20"
				d="M0 0h7.59"
				className="calendar-3"
				data-name="Ligne 20"
				transform="translate(179.252 470.4)"
			/>
			<path
				id="Trac\xE9_25"
				d="M33.913 6.7h3.112a3.758 3.758 0 0 1 3.8 3.8v28.23a3.758 3.758 0 0 1-3.8 3.8H5a3.758 3.758 0 0 1-3.8-3.8V10.5A3.758 3.758 0 0 1 5 6.7h4.853"
				className="calendar-1"
				data-name="Trac\xE9 25"
				transform="translate(156.8 440.475)"
			/>
			<path
				id="Ligne_21"
				d="M0 0h5.769"
				className="calendar-1"
				data-name="Ligne 21"
				transform="translate(180.771 447.175)"
			/>
			<path
				id="Ligne_22"
				d="M0 0h5.92"
				className="calendar-1"
				data-name="Ligne 22"
				transform="translate(170.751 447.175)"
			/>
		</g>
	</svg>
);

export default IconCalendarWithNumber;
