import React from "react";

const IconOceanView = props => (
	<svg viewBox="0 0 49.067 30.769" {...props}>
		<defs>
			<style>
				{
					".ocean-1,.ocean-2{fill:currentColor;stroke-miterlimit:10}.ocean-1{stroke:none;stroke-width:.178px}.ocean-2{stroke:none}"
				}
			</style>
		</defs>
		<g id="OCEAN_VIEW_G" transform="translate(.5 .089)">
			<path
				id="Trac\xE9_24"
				d="M29.452 22.791a9.031 9.031 0 0 0-18.062 0m2.055 0a6.977 6.977 0 0 1 13.953 0"
				className="ocean-1"
				data-name="Trac\xE9 24"
				transform="translate(3.819 -6.118)"
			/>
			<path
				id="Trac\xE9_25"
				d="M18.6 13.527a.828.828 0 0 1-.828.828.828.828 0 0 1-.828-.828V9.418a.828.828 0 0 1 .828-.828.828.828 0 0 1 .828.828z"
				className="ocean-1"
				data-name="Trac\xE9 25"
				transform="translate(6.473 -8.59)"
			/>
			<path
				id="Trac\xE9_26"
				d="M10.231 11.928a.828.828 0 0 0-1.168 0h.015a.828.828 0 0 0 0 1.168l2.956 2.956a.828.828 0 0 0 1.168 0 .828.828 0 0 0 0-1.168z"
				className="ocean-1"
				data-name="Trac\xE9 26"
				transform="translate(2.599 -7.109)"
			/>
			<path
				id="Trac\xE9_27"
				d="M26.254 11.928a.828.828 0 0 1 1.168 0 .828.828 0 0 1 0 1.168l-2.956 2.956a.828.828 0 0 1-1.168 0 .828.828 0 0 1 0-1.168z"
				className="ocean-1"
				data-name="Trac\xE9 27"
				transform="translate(9.397 -7.109)"
			/>
			<path
				id="Trac\xE9_28"
				d="M47.69 27.589a5.809 5.809 0 0 1-4.434-2.025 1.567 1.567 0 0 0-2.365 0 5.809 5.809 0 0 1-8.868 0 1.567 1.567 0 0 0-2.365 0 5.809 5.809 0 0 1-8.868 0 1.567 1.567 0 0 0-2.365 0 5.809 5.809 0 0 1-8.868 0 1.567 1.567 0 0 0-2.365 0 5.809 5.809 0 0 1-4.434 2.025 1.567 1.567 0 1 0 0 3.134 8.868 8.868 0 0 0 5.055-1.567l.532-.4.532.4a8.868 8.868 0 0 0 10.1 0l.532-.4.532.4a8.868 8.868 0 0 0 10.1 0l.532-.4.532.4a8.868 8.868 0 0 0 10.1 0l.532-.4.532.4a8.869 8.869 0 0 0 5.055 1.567 1.567 1.567 0 1 0 0-3.134z"
				className="ocean-2"
				data-name="Trac\xE9 28"
				transform="translate(-1.057 -.733)"
			/>
			<path
				id="Trac\xE9_29"
				d="M2.948 26.483A8.868 8.868 0 0 0 8 24.916l.532-.4.532.4a8.868 8.868 0 0 0 10.1 0l.532-.4.532.4a8.868 8.868 0 0 0 10.1 0l.532-.4.532.4a8.868 8.868 0 0 0 10.1 0l.532-.4.532.4a8.868 8.868 0 0 0 5.044 1.567 1.567 1.567 0 1 0 0-3.134 5.809 5.809 0 0 1-4.434-2.025 1.567 1.567 0 0 0-2.365 0 5.809 5.809 0 0 1-8.868 0 1.567 1.567 0 0 0-2.365 0 5.809 5.809 0 0 1-8.868 0 1.567 1.567 0 0 0-2.365 0 5.809 5.809 0 0 1-8.868 0 1.567 1.567 0 0 0-2.365 0 5.809 5.809 0 0 1-4.434 2.025 1.567 1.567 0 1 0 0 3.134z"
				className="ocean-2"
				data-name="Trac\xE9 29"
				transform="translate(-1.1 -2.76)"
			/>
		</g>
	</svg>
);

export default IconOceanView;
