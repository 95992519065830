import React from "react";

const IconCircleCross = props => (
	<svg className="icon-circle-cross" viewBox="0 0 442 442" {...props}>
		<circle className="icon-circle-cross__circle" cx={221} cy={221} r={221} fill="#fff" />
		<path
			fill="currentColor"
			d="M379.4,133.49a7.91,7.91,0,0,1,0,11.2L268,256.06,379.31,367.33a7.91,7.91,0,0,1,0,11.2,7.76,7.76,0,0,1-5.6,2.31,8,8,0,0,1-5.6-2.31L256,266.42,143.89,378.53a7.76,7.76,0,0,1-5.6,2.31,8,8,0,0,1-5.6-2.31,7.91,7.91,0,0,1,0-11.2L244,256.06,132.6,144.68a7.92,7.92,0,0,1,11.2-11.2L256,245.69,368.21,133.49A7.91,7.91,0,0,1,379.4,133.49Z"
			transform="translate(-35 -35)"
		/>
	</svg>
);

export default IconCircleCross;
