import React from "react";

const IconSearch = props => (
	<svg viewBox="2290 -2858 50 50.065" {...props}>
		<path
			fill="currentColor"
			d="M49.412 47.32L34.248 32.157a19.357 19.357 0 0 0 4.444-12.549A19.527 19.527 0 0 0 19.346 0 19.361 19.361 0 0 0 0 19.608a19.361 19.361 0 0 0 19.346 19.608 19.755 19.755 0 0 0 12.81-4.706L47.32 49.673a1.59 1.59 0 0 0 2.092 0 1.466 1.466 0 0 0 0-2.353zm-30.065-11.3a16.348 16.348 0 0 1-16.21-16.475A16.348 16.348 0 0 1 19.346 3.074a16.348 16.348 0 0 1 16.21 16.471 16.348 16.348 0 0 1-16.21 16.47z"
			data-name="Trac\xE9 530"
			transform="translate(2290 -2858)"
		/>
	</svg>
);

export default IconSearch;
