import React from "react";

const IconLocation = props => (
	<svg viewBox="0 0 21.58 30.76" {...props}>
		<path
			fill="none"
			stroke="currentColor"
			strokeWidth={1.5}
			d="M16.675 2.841a9.778 9.778 0 0 0-13.832 0C-.6 6.285-.978 12.744 1.928 16.673l7.8 11.3 7.8-11.3a11.1 11.1 0 0 0-.853-13.832zM9.893 12.905a3.229 3.229 0 1 1 3.229-3.229 3.239 3.239 0 0 1-3.229 3.229z"
			data-name="Trac\xE9 623"
			transform="translate(1.025 1.025)"
		/>
	</svg>
);

export default IconLocation;
